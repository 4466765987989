<template>
    <div class="hot-Lists">
        <div class="hot-items" v-for="(vals, index) in hotList" :key="index" @click="navTo(vals)">
            <div class="hot-titles">
                <div class="ranking">{{ index + 1 }}</div>
                <span>{{ vals.title }}</span>
            </div>
            <div class="hot-content">
                <el-image class="el-image" v-if="vals.imagetype == 2" lazy :src="baseUrls + vals.image" @click="navTo(vals)" />
                <el-image class="el-image" v-if="vals.imagetype == 1" lazy :src="vals.image" @click="navTo(vals)" />
                <div class="text-actress">
                    <div class="text">
                        <div class="center-tag ">
                          <el-tag size="mini" type="warning" class="item-tags" v-for="(val, index) in vals.tag" :key="index">{{ val }}</el-tag>
                        </div>
                    </div>
                    <div class="text">
                        {{ vals.createtime }}
                    </div>
                </div>
            </div>
        </div>
        <div class="page-centers">
            <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
                :page-size="pageSize" layout="total,prev,pager,next" :total="hotTotal">
            </el-pagination>
        </div>
    </div>
</template>
  
<script>
import { mapState } from 'vuex';
export default {
    name: 'HotItem',
    props: {
        hotList: {
            type: Array,
            default: ''
        },
        hotTotal: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            baseUrls: "https://www.sosunlive.com",
            currentPage: 1,
            pageSize: 10,
        }
    },
    computed: {
        ...mapState(['username'])
    },
    filters: {

    },
    methods: {
        navTo(vals) {
         let self = this;
         self.$router.push("/Receipt/" + vals.id);
         location.reload()
        },
        handleCurrentChange(val) {
            this.$emit('handleHotCurrentChange', val); 
        },
        goToCart() {
            this.$router.push('/cart');
        }
    }
}
</script>
  
<style lang="scss" scoped>
.hot-Lists {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;

    ::-webkit-scrollbar {
        width: 14px;
        height: 14px;
    }

    ::-webkit-scrollbar-track,
    ::-webkit-scrollbar-thumb {
        border-radius: 999px;
        border: 5px solid transparent;
    }

    ::-webkit-scrollbar-track {
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2) inset;
    }

    ::-webkit-scrollbar-thumb {
        min-height: 20px;
        background-clip: content-box;
        box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2) inset;
    }

    ::-webkit-scrollbar-corner {
        background: transparent;
    }

    .page-centers {
        overflow-x: scroll;
    }

    .hot-items {
        width: 100%;
        overflow: hidden;
        margin: 5px auto;
        padding: 5px;
        box-sizing: border-box;
        line-height: 2;
        cursor: pointer;
        border-bottom: 1px dashed #f0f2f7;

        .hot-content {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            padding: 0px;
            box-sizing: border-box;

            .el-image {
                float: left;
                width: 68px;
                border-radius: 3px;
                height: auto;
                overflow: hidden;
                text-align: center;
            }

            .text-actress {
                float: right;
                width: calc(100% - 88px);
                line-height: 1.5;
                overflow: hidden;

                .text {
                    width: 100%;
                    line-height: 1.5;
                    font-size: 13px;
                    overflow: hidden;
                    word-wrap: break-word; //自动换行
                    word-break: break-all; //自动换行（兼容字母）
                    overflow: hidden; //超出隐藏
                    text-overflow: ellipsis; //溢出显示省略号
                    display: -webkit-box;
                    -webkit-line-clamp: 1; //显示3行
                    -webkit-box-orient: vertical;

                    .center-tag {
                        width: 100%;
                        overflow: hidden;
                        margin: 0px auto;

                        .item-tags {
                            margin-top: 8px;
                            margin-left: 8px;
                        }

                        .item-tags:nth-child(1) {
                            margin-left: 0px;
                        }
                    }
                }
            }

        }

        .hot-titles span:hover {
            background: #37a;
            color: #fff;
        }

        .hot-titles {
            color: rgb(51, 119, 170);
            font-size: 14px;
            font-weight: 500;
            font-synthesis: style;
            cursor: pointer;
            overflow: hidden;
            word-wrap: break-word;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            position: relative;
            padding-left: 28px;
            box-sizing: border-box;

            .ranking {
                position: absolute;
                top: 5px;
                left: 5px;
                width: 18px;
                height: 18px;
                display: inline-block;
                color: #fff;
                background: rgb(217, 83, 80);
                text-align: center;
                font-size: 12px;
                line-height: 18px;
                border-radius: 9px;
            }
        }

    }
}
</style>