<template>
    <div class="spider">
      <div>收录电影</div>
    </div>
</template>
<script>
import ApiCon from '../util/Api/index'
import axios from "axios"
export default {
  name: 'Reply',
  data() {
    return {
      List:[],

    }
  },
  created() {

  },
  methods:{

  }
}
</script>
<style lang="scss" scoped>
.spider{
   width: 100%;
   overflow: hidden;
   margin: 0px auto;
}
</style>