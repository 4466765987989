<template>
  <div class="index" id="shoplists">
    <div class="nav-topbar">
      <div class="mobiles-nav-list" v-show="showSea">
        <el-input placeholder="请输入内容" prefix-icon="el-icon-search" v-model="searchText" @input="init" size="mini"
          style="width: 80%; float: left; margin-left: 2%">
        </el-input>
        <a class="nav-items" @click="showSearch"> 关闭 </a>
      </div>
      <div class="mobiles-nav-list">
        <div class="nav-list-centers" v-show="!showSea">
          <div class="nav-items">
            <img src="../../public/imgs/logos.png" style="width: 33px; height: 33px;" />
          </div>
          <div class="nav-items">发现</div>
          <div class="nav-items" @click="NvoToCos()">社区</div>
          <div class="nav-items" @click="showSearch">搜索</div>
        </div>
        <div class="nav-list-scroll">
          <div class="scroll-items" @click="itemActive('推荐', '推荐')" :class="{ 'active-font': '推荐' == activeIndex }">
            推荐
            <span class="line"></span>
          </div>
          <div class="scroll-items" v-for="(val, index) in searchList" :key="index" @click="itemActive(val, index)"
            :class="{ 'active-font': index == activeIndex }">
            {{ val }}
            <span class="line"></span>
          </div>
          <div class="scroll-items" @click="itemActive('编辑', '编辑')" :class="{ 'active-font': '编辑' == activeIndex }">
            编辑
            <span class="line"></span>
          </div>
        </div>
      </div>
      <div class="nav-list">
        <div class="dict-top">
          <div class="hot_centers">
            <div class="hot_items">
              推荐
            </div>
            <div class="hot_items" @click="NvoToCos">
              资源社区
            </div>
            <div class="hot_items" v-for="(val, index) in searchList" :key="index" @click="searchs(val)">
              <span>{{ val }}</span>
            </div>
            <div class="hot_items" @click="dialogFormVisible = true">
              <span>编辑</span>
            </div>
          </div>
        </div>
        <!--
        <div class="nav-items" style="width: 48px;height: 48px;">
          <img src="../../public/imgs/logos.png"
            style="width:38px; height: 38px;position: absolute;top: 5px;left: 5px;" />
        </div>
        <div class="nav-items nav-active"> 发现 </div>
        <div class="nav-items" @click="NvoToCos()"> 资源社区 </div>
        <div class="nav-items">
          <el-input type="text" size="small" v-model="searchText" style="
              border-radius: 16px;width:380px; float: left" clearable></el-input>
          <el-button type="primary" @click="init()" style="width: 68px; float: left; margin-left:12px;margin-top: 8.5px;"
            size="small">搜索</el-button>
        </div>-->
      </div>
    </div>

    <div class="container">
      <div class="search-centers">
        <el-input type="text" size="small" v-model="searchText" style="width:380px; float: left" clearable></el-input>
        <el-button type="primary" @click="init()" style="width: 68px; float: left; margin-left:12px;"
          size="small">搜索</el-button>
      </div>
      <div class="center-font-search" v-if="searchVal" @click="delhottext()">
        清除：{{ searchVal }}
      </div>
      <div class="left">
        <div class="list">
          <div class="items" v-for="(vals, index) in list" :key="index">
            <div class="item-contents" v-show="vals.shows">
              <div class="items-centers">
                <img v-if="vals.imagetype == 2" :src="baseUrls + vals.image" @click="navTo(vals)" />
                <img v-if="vals.imagetype == 1" :src="vals.image" @click="navTo(vals)" />
                <div class="text-actress">
                  <div class="items-title" @click="navTo(vals)">
                    <span>{{ vals.title }}</span>
                  </div>
                  <div class="text">
                    {{ vals.introduction }}
                  </div>
                  <div class="text">
                    <span class="mores" @click="shows(vals, index)">查看更多</span>
                  </div>
                  <div class="text">
                    {{ vals.filmcountry }} | {{ vals.description }}
                  </div>
                  <div class="text">
                    {{ vals.actress }}
                  </div>
                </div>
              </div>
              <div class="center-tag">
                <el-tag size="mini" type="warning" class="item-tags" v-for="(val, index) in vals.tag" :key="index">{{ val
                }}</el-tag>
              </div>
            </div>
            <div class="item-contents" v-show="!vals.shows">
              <div class="items-title" @click="navTo(vals)">
                {{ vals.title }}
              </div>
              <div class="items-centers">
                <div class="video-centers" v-if="vals.attachfile">
                  <video :src="baseUrls + vals.attachfile" width="100%" height="auto" controls></video>
                </div>
                <div class="body-centers">
                  <div v-html="vals.maincontent"></div>
                  <span class="packup" @click="shows(vals, index)">收起</span>
                </div>
              </div>
            </div>
            <div class="btn_centers">
              <div class="items-li">{{ vals.createtime }}</div>
            </div>
          </div>
        </div>
        <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize"
          layout="total,prev,pager,next" :total="total">
        </el-pagination>
      </div>
      <div class="right">
        <div class="login-centers">
          <div class="login-title" v-show="active == 1">登录</div>
          <div class="login-title" v-show="active == 2">注册</div>
          <el-form v-show="active == 1" ref="loginForm" :model="loginForm" autocomplete="on" label-position="left">
            <el-form-item prop="account">
              <el-input ref="account" v-model="loginForm.account" placeholder="请输入手机号" name="account" type="text"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input ref="password" v-model="loginForm.password" placeholder="请输入密码" name="password" type="password"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <el-button type="primary" size="small" @click.native.prevent="handleLogin">登录</el-button>
            <el-button type="primary" size="small" @click.native.prevent="isResgister">注册</el-button>
          </el-form>
          <el-form v-show="active == 3" ref="userInfo" autocomplete="on" label-position="center">
            <el-form-item style="margin-bottom: 0px" align="center" bottom="0px">
              <el-form-item align="center" style="margin-bottom: 0px; text-align: center">
                <img :src="'https://www.sosunlive.com' + userInfo.avatar" style="width: 48px; height: auto; border-radius: 3px" />
              </el-form-item>
              <el-form-item prop="username" align="center" bottom="0px">
                <span>{{ userInfo.username }} | 积分：{{ userInfo.score }}</span>
              </el-form-item>
              <!--
              <el-form-item prop="score" align="center" bottom="0px">
                <span>积分：{{ userInfo.score }}</span>
              </el-form-item>-->
              <el-button type="primary" @click="active = 4" size="mini" style="margin-left: 0px">设置</el-button>
              <el-button type="primary" @click="logout" size="mini" style="margin-left: 10px">注销</el-button>
              <!--
              <el-button type="primary" icon="el-icon-s-comment" @click="navToMes()" size="mini"
                style="margin-left: 10px">我的评论</el-button>
              <el-button type="primary" icon="el-icon-s-comment" @click="navToReply()" size="mini"
                style="margin-left: 10px">回复我的</el-button>
              <el-button type="primary" icon="el-icon-s-comment" @click="navToRes()" size="mini"
                style="margin-left: 10px">我的视图</el-button>-->
            </el-form-item>
          </el-form>
          <el-form v-show="active == 4" ref="userForm" :model="userForm" autocomplete="on" label-position="left">
            <el-form-item prop="username">
              <el-input ref="username" v-model="userForm.username" placeholder="新昵称" name="username" type="text"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input ref="password" v-model="userForm.password" placeholder="新密码" name="password" type="password"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <el-form-item prop="address">
              <el-input ref="address" v-model="userForm.address" placeholder="新地址" name="address" type="text"
                tabindex="1" autocomplete="on" size="large">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-upload class="upload-demo" name="file" action="https://sosunlive.com/api/common/upload"
                :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileLists" :on-success="handleSuccesss"
                list-type="picture">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，且不超过500kb
                </div>
              </el-upload>
            </el-form-item>
            <el-button type="primary" size="small" @click.native.prevent="setUserInfo">提交</el-button>
            <el-button type="primary" size="small" @click="active = 3">返回</el-button>
          </el-form>
          <el-form v-show="active == 2" :model="registerForm" ref="registerForm" autocomplete="on" label-position="left">
            <el-form-item>
              <el-input v-model="registerForm.username" placeholder="请输入昵称" name="nickname" type="text" size="large">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="registerForm.mobile" placeholder="请输入手机号码">
                <el-button slot="suffix" size="mini" style="border: none" @click="sendcode" :disabled="!show">
                  获取验证码
                  <span v-show="!show" class="count">({{ count }}s)</span>
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="registerForm.authcode" placeholder="请输入验证码" name="authcode" type="text" size="large">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="registerForm.password" placeholder="请输入密码" name="password" type="password" size="large">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="registerForm.address" placeholder="请输入地址" name="address" type="text" size="large">
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-upload class="upload-demo" name="file" action="https://sosunlive.com/api/common/upload"
                :on-preview="handlePreview" :on-remove="handleRemove" :on-success="handleSuccess" :file-list="fileList"
                :before-upload="beforeUpload" list-type="picture" :limit="1" accept=".gif,.jpg,.png">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，且不超过500kb
                </div>
              </el-upload>
            </el-form-item>
            <el-button type="primary" size="small" @click.native.prevent="handResgister">注册</el-button>
            <el-button type="primary" size="small" @click.native.prevent="isResgister">登录</el-button>
          </el-form>
        </div>
        <div class="user-centers">
          <div class="user-text" v-show="active == 1">请先登陆/注册</div>
          <div class="user-info" v-show="active == 3">
            <div class="item" @click="navToRes()" >
              <img src="../../public/imgs/zi1.png" style="width: 38px;height: auto;" />
              <div class="item-name">我的视/图</div>
            </div>
            <div class="item"  @click="navToMes()">
              <img src="../../public/imgs/zi2.png" style="width: 38px;height: auto;" />
              <div class="item-name">我的评论</div>
            </div>
            <div class="item" @click="navToReply()">
              <img src="../../public/imgs/zi3.png" style="width: 38px;height: auto;" />
              <div class="item-name">回复我的</div>
            </div>
          </div>
        </div>
        <div class="hot_lists">
          <div class="hot_top_tites">很想很想你的热榜 · · · · · ·</div>
          <HotItem :hotList="hotList" :hotTotal="hotTotal" @handleHotCurrentChange="handleHotCurrentChange"></HotItem>
        </div>
        <div class="web_text">
          <div class="font-centers">
            <div class="font">浙ICP备17001033号-1</div>
            <div class="font">关注“暖心小生活Live”公众号更方便访问</div>
            <div class="wxinfo">
              <img src="../../public/imgs/gongzhonghaocode.jpg" width="100%" height="auto" />
            </div>
            <div class="font">本站遵守国家法律法规 如发现非法信息</div>
            <div class="font">请立即联系我们(QQ：3556766711)</div>
            <div class="font">临海市暖心日用百货商行 版权所有</div>
            <div class="font">Copyright © 2024 All rights reserved.</div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="编辑热点" :visible.sync="dialogFormVisible" :width="width">
      <div class="navCenters">
        <div class="navItems" v-for="(val, isndexs) in searchList" :key="isndexs" @click="deletes(val)">
          {{ val }}
        </div>
      </div>
      <div class="add-text">
        <el-input type="text" tabindex="1" autocomplete="on" size="large" v-model="navText"
          style="width: 79%; float: left"></el-input>
        <el-button type="primary" @click="addsNav()" style="width: 19%; float: right">添加</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import ApiCon from "../util/Api/index";
import HotItem from '@/components/HotItem';
export default {
  name: "index",
  components: {
    HotItem
  },
  data() {
    return {
      searchList: [
        "附身",
        "黑化",
        "寄生",
        "反派",
        "高颜值"
      ],
      value: "",
      activeIndex: "推荐",
      width: "40%",
      searchVal: "",
      options: [],
      loading: false,
      meType: 1,
      navText: "",
      searchText: "",
      total: 1,
      dialogFormVisible: false,
      active: 1,
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      baseUrl: "",
      baseUrls: "https://www.sosunlive.com",
      timer: null, //计时器
      code: "", //验证码
      count: "", //倒计时
      show: true, //控制按钮
      loginForm: {
        account: "",
        password: "",
      },
      userInfo: {
        avatar:
          "/assets/img/avatar.png",
        nickname: "您好",
        score:'0'
      },
      userForm: {
        password: "",
        address: "",
        avatar: "",
      },
      registerForm: {
        mobile: "",
        password: "",
        address: "",
        code: "",
        avatar: "",
        authcode: "",
        username: "",
      },
      showSea: false,
      list: [],
      hotList: [],
      hotTotal: 1,
      fileList: [
        // { name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }
      ],
      fileLists: [
        // { name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }
      ],
    };
  },
  mounted() {
    this.ApiCon = new ApiCon(this.$store);
    this.ApiConUrl = this.ApiCon.getApiUrl();
  },
  created() {
    this.ApiCon = new ApiCon(this.$store);
    //console.log(document.body.scrollTop)
    //document.body.scrollTop = 0
    //document.documentElement.scrollTop =0
    //this.$layer.msg('你好')
    //localStorage.setItem("hotText","Kiss")
    localStorage.setItem("hotText","附身")
    this.ApiConUrl = this.ApiCon.getApiUrl();
    this.baseUrl = this.ApiConUrl;
    let val = localStorage.getItem("hotText");
    if (val) {
      this.searchs(val)
      this.getHotLists()
    } else {
      this.init()
      this.getHotLists()
    }
    if (window.screen.width > 414) {
      this.width = "40%"
    } else {
      this.width = "98%"
    }
    let userInfo = window.localStorage.getItem("userinfo");
    if (userInfo) {
      this.active = 3;
      this.userInfo = JSON.parse(userInfo)
    }else{
      this.$router.push("/Login");
    }
    if (window.localStorage.getItem("token")) {
      this.getUserInfos()
    }
    let searchList = window.localStorage.getItem("newsearchList");
    if (searchList) {
      this.searchList = JSON.parse(searchList);
    }else{
      window.localStorage.setItem("newsearchList", JSON.stringify(this.searchList));
    }
  },
  methods: {
    delhottext() {
      localStorage.setItem("hotText", "");
      this.activeIndex = "推荐";
      this.searchVal = "";
      this.init();
    },
    handleSuccess(res, file, fileList) {
      if (res.code == 1) {
        this.fileList.push({
          name: res.data.url,
          fileId: res.data.url,
          url: res.data.url,
        });
        this.$emit("input", this.fileList);
      } else {
        fileList.filter((o) => o.uid != file.uid);
        this.$emit("input", this.fileList);
        this.$message({ message: res.msg, type: "error", duration: 1500 });
      }
    },
    handleSuccesss(res, file, fileList) {
      if (res.code == 1) {
        this.fileLists.push({
          name: res.data.url,
          fileId: res.data.url,
          url: res.data.url,
        });
        this.$emit("input", this.fileLists);
      } else {
        fileList.filter((o) => o.uid != file.uid);
        this.$emit("input", this.fileLists);
        this.$message({ message: res.msg, type: "error", duration: 1500 });
      }
    },
    beforeUpload(file) {
      //console.log(file, "99999");
      let isRightSize = file.size / (1024 * 500) < 0.5;
      if (!isRightSize) {
        this.$message.error(`文件大小超过500KB`);
        return false;
      }
    },
    getUserInfos() {
      let self = this;
      this.$api.mall
        .getUserInfo({})
        .then((ress) => {
          self.active = 3;
        })
        .catch((err) => {
          //console.log(err,"Ssbbb9900")
          self.$message.error(err.data.msg);
          if (err.data.code == "401") {
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("userinfo");
            self.active = 1;
          }
        });
    },
    showSearch() {
      let self = this;
      if (self.showSea == false) {
        self.showSea = true;
      } else {
        self.showSea = false;
      }
    },
    navToSpi() {
      let self = this;
      self.$message.success("正在开发");
      // self.$router.push("/Spider")
    },
    navToRes() {
      //ResList
      let self = this;
      self.$router.push("/ResList");
    },
    navToReply() {
      let self = this;
      self.$router.push("/SpeakReply");
    },
    navToMes() {
      let self = this;
      self.$router.push("/Speak");
    },
    navTo(vals) {
      let self = this;
      self.$router.push("/detail/" + vals.id);
    },
    NvoToCos() {
      let self = this;
      self.$router.push("/community");
    },
    meShow(index) {
      let self = this;
      self.meType = index;
      self.init();
    },
    deletes(val) {
      let self = this;
      let searchList = window.localStorage.getItem("PosssearchList");
      if (searchList) {
        //self.searchList = searchList;
        self.searchList.splice(val, 1);
        window.localStorage.setItem("PosssearchList", JSON.stringify(self.searchList));
        self.$message.success("设置成功，请刷新页面");
      } else {
        self.$message.error("请先添加");
      }
    },
    addsNav() {
      let self = this;
      if (!self.navText) {
        this.$message.error("请输入关键字");
        return;
      }
      let searchList = window.localStorage.getItem("PosssearchList");
      if (searchList) {
        let array = self.searchList;
        array.push(self.navText);
        window.localStorage.setItem("PosssearchList", JSON.stringify(array));
        self.$message.success("设置成功，请刷新页面");
      } else {
        let array = [
            "附身",
            "黑化",
            "寄生",
            "反派",
            "高颜值"
        ];
        array.push(self.navText);
        window.localStorage.setItem("PosssearchList", JSON.stringify(array));
        self.$message.success("设置成功，请刷新页面");
      }
    },
    itemActive(item, index) {
      let self = this;
      if (index != "编辑") {
        self.activeIndex = index;
      }
      if (item == "推荐") {
        self.searchs("");
        return;
      }
      if (item == "编辑") {
        self.dialogFormVisible = true;
        return;
      }
      self.searchs(item);
    },
    searchs(text) {
      this.searchVal = text;
      this.currentPage = 1;
      this.searchText = "";
      localStorage.setItem("hotText", text);
      this.list = [];
      this.$api.mall
        .getList({
          page: this.currentPage,
          limit: this.pageSize,
          tag: this.searchVal,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true;
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }
          this.total = res.data.total;
          this.list = res.data.data;
          
          document.body.scrollTop = 0
          document.documentElement.scrollTop =0
          //console.log(this.list, "9998888")
        });
    },
    sendcode() {
      if (!this.timer) {
        this.count = 60;
        //sendAliDaYuAuthCode
        let self = this;
        // console.log(self.registerForm,"iiiiii")
        if (!self.registerForm.mobile) {
          self.$message.error("请先输入手机号码");
          return;
        }
        var reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (!reg.test(self.registerForm.mobile)) {
          self.$message.msg("请填写正确的手机号码");
          return;
        }
        self.$api.mall
          .sendAliDaYuAuthCode({ phone: self.registerForm.mobile })
          .then((res) => {
            if (res.code == 1) {
              this.show = false;
              self.$message.success("发送成功");
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= 60) {
                  this.count--;
                } else {
                  this.show = true;
                  clearInterval(this.timer); // 清除定时器
                  this.timer = null;
                }
              }, 1000);
            } else {
              self.$message.error("发送失败");
            }
          });
      }
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      //this.ApiCon = new ApiCon(this.$store)
      //this.ApiConUrl = this.ApiCon.getApiUrl()
      this.ApiCon.addPage(val);
      //console.log(this.ApiCon.gets('pages'))
      //this.$router.push("/cart");
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.currentPage = val;
      this.list = [];
      this.$api.mall
        .getList({
          page: this.currentPage,
          limit: this.pageSize,
          title: this.searchText,
          tag: this.searchVal,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true;
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }
          this.total = res.data.total;
          this.list = res.data.data;
        });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleHotCurrentChange(val){
      this.hotList = [];
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      this.$api.mall
        .getHotList({
          page: val,
          limit: this.pageSize,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true;
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }
          this.hotTotal = res.data.total;
          this.hotList = res.data.data;
        });
    },
    getHotLists() {
      this.hotList = [];
      this.$api.mall
        .getHotList({
          page: 1,
          limit: this.pageSize,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true;
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }
          this.hotTotal = res.data.total;
          this.hotList = res.data.data;
        });
    },
    // 初始化
    init() {
      this.currentPage = 1;
      if (this.ApiCon.gets("pages")) {
        this.currentPage = this.ApiCon.gets("pages");
      }
      this.list = [];
      this.$api.mall
        .getList({
          page: this.currentPage,
          limit: this.pageSize,
          title: this.searchText,
        })
        .then((ress) => {
          let res = ress.data;
          for (var i = 0; i < res.data.data.length; i++) {
            res.data.data[i].shows = true;
            res.data.data[i].tag = res.data.data[i].tag.split(",");
          }

          this.total = res.data.total;
          this.list = res.data.data;
          
          document.body.scrollTop = 0
          document.documentElement.scrollTop =0
        });
    },
    //登录
    handleLogin() {
      let self = this;
      if (!self.loginForm.account) {
        self.$message.error("请输入手机号码");
        return;
      }
      if (!self.loginForm.password) {
        self.$message.error("请输入密码");
        return;
      }
      self.$api.mall.login(self.loginForm).then((res) => {
        if (res.code == 1) {
          self.active = 3;
          self.userInfo = res.data.userinfo;
          window.localStorage.setItem(
            "userinfo",
            JSON.stringify(res.data.userinfo)
          );
          window.localStorage.setItem("token", res.data.userinfo.token);
          self.$message.success("登陆成功");
        } else {
          self.$message.error("登陆失败");
        }
      });
    },
    setUserInfo() {
      let self = this;
      if (self.fileLists.length > 0) {
        self.userForm.avatar = self.fileLists[0].url;
      }
      self.$api.mall.setUserInfo(self.userForm).then((res) => {
        console.log(res);
        if (res.code == 1) {
          self.active = 3;
          window.localStorage.setItem(
            "userinfo",
            JSON.stringify(res.data.userinfo)
          );
          window.localStorage.setItem("token", res.data.userinfo.token);
          
          self.userInfo =res.data.userinfo;
          self.$message.success("修改成功");
        } else {
          self.$message.error("修改失败");
        }
      });
    },
    logout() {
      let self = this
      window.localStorage.removeItem("token")
      window.localStorage.removeItem("userinfo")
      self.active = 1
    },
    handResgister() {
      let self = this;
      if (self.fileList.length > 0) {
        self.registerForm.avatar = self.fileList[0].url;
      }
      if (!self.registerForm.mobile) {
        self.$message.error("请输入手机号码");
        return;
      }
      if (!self.registerForm.username) {
        self.$message.error("请输入昵称");
        return;
      }
      if (!self.registerForm.password) {
        self.$message.error("请输入密码");
        return;
      }
      if (!self.registerForm.authcode) {
        self.$message.error("请输入验证码");
        return;
      }
      self.$api.mall.register(self.registerForm).then((res) => {
        console.log(res);
        if (res.code == 1) {
          self.active = 3;
          //console.log(res.data.userinfo);
          self.userInfo = res.data.userinfo;

         // console.log(self.userInfo.avatar);
          window.localStorage.setItem(
            "userinfo",
            JSON.stringify(res.data.userinfo)
          );
          window.localStorage.setItem("token", res.data.userinfo.token);
          self.$message.success("注册成功");
        } else {
          self.$message.error("注册失败");
        }
      });
    },
    shows(vals, index) {
      let self = this;
      if (self.list[index].shows == true) {
        self.list[index].shows = false;
      } else {
        self.list[index].shows = true;
      }
    },
    isResgister() {
      let self = this;
      if (self.active == 1) {
        self.active = 2;
      } else {
        self.active = 1;
      }
    },
  },
};
</script>
<style lang='scss' scoped>
@media (max-width: 1920px) and (min-width: 1024px) {
  .index {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;

    .add-text {
      width: 100%;
      overflow: hidden;
      margin-top: 12px;
    }

    .navCenters {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;

      .navItems {
        width: 17.5%;
        cursor: pointer;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        white-space: nowrap;
        -webkit-box-orient: vertical;
        margin-left: 1.5%;
        margin-top: 12px;
        border: 1px solid #f0f0f0;
        overflow: hidden;
        line-height: 32px;
        text-align: center;
        float: left;
        border-radius: 8px;
        background: url(../../public/imgs/deletes.png) no-repeat right;
        background-size: 15px;
      }
    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      background: #fff;
      position: fixed;
      -webkit-box-shadow: 0 1px 3px hsla(0, 0%, 7%, .1);
      box-shadow: 0 1px 3px hsla(0, 0%, 7%, .1);
      top: 0px;
      left: 0px;
      z-index: 10;

      .mobiles-nav-list {
        display: none;
      }

      .nav-list {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        padding: 0px;
        z-index: 10;
        background: #ffe url('/imgs/fade.png') top center repeat-x;
        color: maroon;
        position: fixed;
        box-sizing: border-box;

        .dict-top {
          width: 980px;
          overflow: hidden;
          margin: 0px auto;
          padding: 0px;
          box-sizing: border-box;

          .hot_centers {
            width: 100%;
            line-height: 33px;
            overflow: hidden;
            margin: 0px auto;
            padding: 0px;

            .hot_items {
              padding-left: 12px;
              padding-right: 8px;
              float: left;
              text-align: center;
              display: block;
              cursor: pointer;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .hot_items span:hover {
              color: red !important;
            }
          }
        }
      }
    }

    .container {
      width: 980px;
      overflow: hidden;
      margin: 48px auto 38px auto;
      line-height: 28px;

      .search-centers {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        margin: 10px auto;
        padding: 0px;
      }

      .center-font-search {
        width: 98%;
        cursor: pointer;
        overflow: hidden;
        margin: 0px auto;
        text-align: left;
        color: #072;
        font-size: 16px;
      }

      .center-font-search:hover {
        text-decoration: underline;
      }

      .center-font {
        width: 100%;
        cursor: pointer;
        overflow: hidden;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0px auto;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        font-size: 16px;
      }

      .left {
        width: 680px;
        overflow: hidden;
        float: left;
        border-radius: 3px;

        .list {
          width: 100%;
          overflow: hidden;
          margin: auto auto 10px auto;
          padding: 0px;
          box-sizing: border-box;

          .items {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            border-bottom: 1px dashed #f0f2f7;
            background: #fff;
            box-sizing: border-box;
            padding: 10px;
            font-size: 13px;

            .center-tag {
              width: 100%;
              overflow: hidden;
              margin: 8px auto;

              .item-tags {
                margin-left: 8px;
              }

              .item-tags:nth-child(1) {
                margin-left: 0px;
              }
            }

            .packup {
              display: inline-block;
              height: auto;
              cursor: pointer;
              padding: 0;
              line-height: inherit;
              background-color: transparent;
              font-size: 13px;
              margin-left: 4px;
              color: #175199;
            }

            .items-contents {
              width: 100%;
              overflow: hidden;
              margin: 0px auto;
              padding: 0px;
            }

            .btn_centers {
              width: 100%;
              overflow: hidden;
              margin: 0px auto;
              padding: 0px;

              .items-li {
                float: left;
                overflow: hidden;
                color: #999;
                text-align: center;
                line-height: 15px;
                margin-top: 6px;
              }
            }

            .items-centers {
              width: 100%;
              overflow: hidden;
              margin: 0px auto;
              padding: 0px;

              img {
                float: left;
                width: 98px;
                border-radius: 3px;
                height: auto;
                overflow: hidden;
                cursor: pointer;
                text-align: center;
              }

              .text-actress {
                float: right;
                width: calc(100% - 128px);
                line-height: 1.5;
                overflow: hidden;

                .items-title {
                  color: rgb(51, 119, 170);
                  font-weight: 500;
                  font-size: 14px;
                  font-synthesis: style;
                  cursor: pointer;
                }

                .items-title span:hover {
                  background: #37a;
                  color: #fff;
                }

                .text {
                  width: 100%;
                  line-height: 1.5;
                  overflow: hidden;
                  word-wrap: break-word; //自动换行
                  word-break: break-all;
                  text-overflow: ellipsis; //溢出显示省略号
                  display: -webkit-box;
                  -webkit-line-clamp: 1; //显示3行
                  -webkit-box-orient: vertical;

                  .mores {
                    display: inline-block;
                    height: auto;
                    cursor: pointer;
                    padding: 0;
                    line-height: inherit;
                    background-color: transparent;
                    margin-left: 4px;
                    color: #175199;
                  }
                }
              }
            }
          }
        }
      }

      .right {
        width: 290px;
        overflow: hidden;
        float: right;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px auto;

        .user-centers {
          width: 100%;
          overflow: hidden;
          box-sizing: border-box;
          margin: 10px auto;
          padding: 0px;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;

          .user-info {
            width: 100%;
            overflow: hidden;
            box-sizing: border-box;
            margin: 0px auto;
            padding: 0px;

            .item {
              width: 88px;
              cursor: pointer;
              overflow: hidden;
              float: left;
              margin: 0px auto;
              box-sizing: border-box;
              padding: 10px;
              text-align: center;
            }
          }

          .user-text {
            color: maroon;
            font-size: 14px;
            line-height: 1.22;
            text-align: center;
            margin: 12px 0;
            word-wrap: break-word;
            font-weight: 500;
            font-synthesis: style;
          }
        }


        .web_text {
          /**top:390px */
          width: 100%;
          overflow: hidden;
          margin: 10px auto;
          box-sizing: border-box;
          padding: 10px 15px 10px;
          min-width: 0px;
          color: rgb(132, 145, 165);
          font-size: 14px;
          line-height: 2;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;

          .font-centers {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;

            .font {
              width: 100%;
              overflow: hidden;
              box-sizing: border-box;
            }
          }
        }

        .hot_lists {
          width: 100%;
          margin: 10px auto;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;

          .hot_top_tites {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            color: rgb(217, 83, 80);
            font-size: 14px;
          }
        }

        .login-centers {
          margin: 0px auto;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;
          width: 100%;
          font-size: $fontJ;
          box-sizing: border-box;
          padding: 10px;
          overflow: hidden;
        }
      }
    }

    .nav_centers {
      width: 980px;
      overflow: hidden;
      margin: 0px auto;
      line-height: 28px;
      border-bottom: 1px solid maroon;

      .nav_itemss {
        cursor: pointer;
        float: left;
        width: 38px;
        background: #fff;
        color: maroon;
        margin-left: 12px;
        text-align: center;
        overflow: hidden;
      }

      .nav_items {
        cursor: pointer;
        float: left;
        width: 38px;
        background: maroon;
        color: #fff;
        margin-left: 12px;
        text-align: center;
        overflow: hidden;
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 320px) {
  .index {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;

    .el-pagination {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .add-text {
      width: 100%;
      overflow: hidden;
      margin-top: 12px;
    }

    .navCenters {
      width: 100%;
      overflow: hidden;
      margin: 0px auto;

      .navItems {
        width: 17.5%;
        cursor: pointer;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        white-space: nowrap;
        -webkit-box-orient: vertical;
        margin-left: 1.5%;
        margin-top: 12px;
        border: 1px solid #f0f0f0;
        overflow: hidden;
        line-height: 32px;
        text-align: center;
        float: left;
        border-radius: 8px;
        background: url(../../public/imgs/deletes.png) no-repeat right;
        background-size: 18px;
      }
    }

    .dict-top {
      display: none;
    }

    .container {
      width: 100%;
      overflow: hidden;
      margin: 72px auto auto auto;
      padding: 0px;

      .search-centers {
        display: none;
      }
      .center-font-search{  
          width: 98%;
          overflow: hidden;
          margin: 10px auto auto auto;
          text-align: left;
          color: #072;
          font-size: 16px;
      }

      .left {
        width: 99%;
        overflow: hidden;
        /**
        border-radius: 3px;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        **/
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0px auto;

        .list {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;

          .items:nth-child(1) {
            margin: 10px auto auto auto;
          }

          .items {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;
            border-bottom: 1px dashed #f0f2f7;
            background: #fff;
            box-sizing: border-box;
            padding: 16px 15px 10px;

            .center-tag {
              width: 100%;
              overflow: hidden;
              margin: 8px auto;

              .item-tags {
                margin-left: 8px;
              }

              .item-tags:nth-child(1) {
                margin-left: 0px;
              }
            }

            .btn_centers {
              width: 100%;
              overflow: hidden;
              margin: 0px auto;
              padding: 0px;

              .items-li {
                float: left;
                color: #999;
                overflow: hidden;
                text-align: center;
                line-height: 15px;
                margin-top: 6px;
              }
            }

            .items-centers {
              width: 100%;
              overflow: hidden;
              margin: 0px auto;
              padding: 0px;

              img {
                width: 98px;
                float: left;
                height: auto;
                margin: 0px auto;
                overflow: hidden;
                cursor: pointer;
                text-align: center;
              }

              .text-actress {
                float: right;
                width: calc(100% - 108px);
                line-height: 1.5;
                overflow: hidden;

                .items-title {
                  color: rgb(51, 119, 170);
                  font-size: 14px;
                  font-weight: 500;
                  font-synthesis: style;
                  width: 100%;
                  overflow: hidden;
                  word-wrap: break-word; //自动换行
                  word-break: break-all; //自动换行（兼容字母）
                  text-overflow: ellipsis; //溢出显示省略号
                  display: -webkit-box;
                  -webkit-line-clamp: 1; //显示3行
                  -webkit-box-orient: vertical;
                }

                .text {
                  width: 100%;
                  line-height: 1.5;
                  overflow: hidden;
                  font-size: 14px;
                  word-wrap: break-word; //自动换行
                  word-break: break-all; //自动换行（兼容字母）
                  text-overflow: ellipsis; //溢出显示省略号
                  display: -webkit-box;
                  -webkit-line-clamp: 1; //显示3行
                  -webkit-box-orient: vertical;

                  .mores {
                    display: inline-block;
                    height: auto;
                    cursor: pointer;
                    padding: 0;
                    line-height: inherit;
                    background-color: transparent;
                    margin-left: 4px;
                    color: #175199;
                  }
                }
              }
            }

            .packup {
              display: inline-block;
              height: auto;
              padding: 0;
              line-height: inherit;
              background-color: transparent;
              font-size: 14px;
              margin-left: 4px;
              color: #175199;
            }
          }
        }
      }

      .right {
        width: 100%;
        overflow: hidden;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 10px;
        margin: 10px auto;
        .user-centers {
          width: 100%;
          overflow: hidden;
          box-sizing: border-box;
          margin: 10px auto;
          padding: 0px;
          border-radius: 2px;
          -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
          background: #fff;
          .user-info {
            width: 100%;
            overflow: hidden;
            box-sizing: border-box;
            margin: 0px auto;
            padding: 0px;
            .item {
              width: 88px;
              overflow: hidden;
              float: left;
              margin: 0px auto;
              box-sizing: border-box;
              padding: 10px;
              text-align: center;
            }
          }

          .user-text {
            color: maroon;
            text-align: center;
            font-size: 14px;
            line-height: 1.22;
            margin: 12px 0;
            word-wrap: break-word;
            font-weight: 500;
            font-synthesis: style;
          }
        }

        .login-title {
          width: 100%;
          overflow: hidden;
          text-align: left;
          line-height: 30px;
        }

        .web_text {
          /**top:390px */
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          box-sizing: border-box;
          padding: 0px;
          color: rgb(132, 145, 165);
          font-size: 14px;
          line-height: 2;

          .font-centers {
            width: 100%;
            overflow: hidden;
            margin: 0px auto;

            .wxinfo {
              display: none;
            }

            .font {
              width: 100%;
              overflow: hidden;
              box-sizing: border-box;
            }
          }
        }

        .hot_lists {
          display: none;
        }

        .hot_centers {
          display: none;
        }
      }
    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;
      background: #fff;

      .mobiles-nav-list {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding-top: 5px;
        margin: 0px auto;

        .nav-list-centers {
          width: 100%;
          overflow: hidden;
          box-sizing: border-box;
          padding: 0px;
          margin: 0px auto;
        }

        .nav-list-scroll::-webkit-scrollbar {
          display: none;
        }

        .nav-list-scroll {
          width: 100%;
          white-space: nowrap;
          overflow-y: auto;
          box-sizing: border-box;
          padding: 0px;
          margin: 0px auto;

          .active-font {
            color: #ff4b53;
            font-weight: 600;

            .line {
              display: block !important;
              position: absolute;
              width: 38px;
              left: 15px;
              height: 2px;
              background: #ff4b53;
              bottom: 3px;
            }
          }

          .scroll-items {
            width: 68px;
            height: 35px;
            line-height: 35px;
            overflow: hidden;
            text-align: center;
            display: inline-block;
            position: relative;

            .line {
              display: none;
            }
          }
        }

        .nav-items {
          width: 49px;
          height: 35px;
          line-height: 35px;
          overflow: hidden;
          text-align: center;
          display: block;
          float: left;
        }
      }

      .nav-list {
        display: none;
      }
    }
  }
}</style>