<template>
  <div class="replys">
    <div class="nav-topbar">
      <div class="mobiles-nav-list">
        <a class="nav-backs" @click="backs()"> 返回 </a>
      </div>
      <div class="nav-list">
        <a class="nav-backs" @click="backs()"> 返回 </a>
        <a class="nav-items">提交视图</a>
      </div>
    </div>
    <div class="centers-el">
      <div class="centers-images">
        <el-radio-group v-model="type" size="small">
          <el-radio label="1">图片</el-radio>
          <el-radio label="2">视频</el-radio>
          <!--<el-radio label="4">云盘</el-radio>
          <el-radio label="5">网站</el-radio>
          -->
        </el-radio-group>
      </div>
      <div class="centers-images" v-if="type == 1">
        <el-upload class="upload-demo" name="file" action="https://sosunlive.com/api/common/upload"
          :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileList" :before-upload="beforeUpload"
          :on-success="handleSuccess" list-type="picture" :limit="10" accept=".gif,.jpg,.png">
          <el-button size="small" type="primary">点击上传图片组</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg|png文件
          </div>
        </el-upload>
      </div>

      <div class="centers-images" v-if="type == 2">
        <el-upload class="upload-demo" name="file" action="https://sosunlive.com/api/common/upload"
          :on-preview="handlePreview" :on-remove="handleRemove" :file-list="fileLists" :before-upload="beforeUploads"
          :on-success="handleSuccesss" accept=".mp4" :limit="1">
          <el-button size="small" type="primary">点击上传视频</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传mp4文件，且不超过35mb
          </div>
        </el-upload>
      </div>

      <div class="media-center" v-if="type == 4">
        <div class="editor-center">
          <div class="inputs">
            <el-input type="text" placeholder="请输入云盘链接" v-model="yunlink">
            </el-input>
          </div>
        </div>
        <div class="editor-center">
          <div class="inputs">
            <el-input type="text" placeholder="请输入云盘分享码" v-model="yuncode">
            </el-input>
          </div>
        </div>
      </div>
      <div class="media-center" v-if="type == 5">
        <div class="editor-center">
          <div class="inputs">
            <el-input type="text" placeholder="请输入视频网站链接" v-model="weblink">
            </el-input>
          </div>
        </div>
      </div>
      <!--
      <div class="centers-images">
        <el-upload
          class="upload-demo"
          name="file"
          action="https://sosunlive.com/api/common/upload"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :file-list="fileListss"
          :before-upload="beforeUploadss"
          :on-success="handleSuccessss"
          list-type="picture"
          :limit="1"
          accept=".gif,.jpg,.png"
        >
          <el-button size="small" type="primary">点击上传付款码</el-button>
          <div slot="tip" class="el-upload__tip">
            只能上传jpg|png文件，且不超过500KB
          </div>
        </el-upload>
      </div>-->
      <div class="editor-center">
        <div class="inputs">
          <el-input type="text" placeholder="请输入标题" v-model="title" disabled>
          </el-input>
        </div>
      </div>
      <div class="movie-body" v-if="info.filmname">
        <el-row>
          <el-col :span="24">
            <el-card :body-style="{ padding: '0px' }">
              <div class="filename-centers">
                <div class="filename-pic">
                  <el-image v-if="info.imagetype == 2" :src="baseUrls + info.file" class="image"
                    style="width: 99%;height: auto;margin: 0px auto;" />
                  <el-image v-if="info.imagetype == 1" :src="info.file" class="image"
                    style="width: 99%;height: auto;margin: 0px auto;" />
                </div>
                <div class="filename-content">
                  <div style="padding: 6px;">
                    <div class="font-text">{{ info.filmname }}</div>
                    <div class="font-text">年份：{{ info.year }}</div>
                    <div class="font-text">导演：{{ info.director }}</div>
                    <div class="font-text" v-if="info.screenwriter"> 编剧：{{
                      info.screenwriter }}
                    </div>
                    <div class="font-text">演员：{{ info.actress }}</div>
                    <div class="font-text" v-if="info.description">类型：{{
                      info.description }}
                    </div>
                    <div class="font-text">地区（国家）：{{ info.filmcountry }}
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="page-el">
      <div class="page-bottom">
        <div class="left">发布视图,有奖品！O(∩_∩)O......</div>
        <div class="right">
          <el-button type="danger" @click="submit()" size="medium">提交</el-button>
        </div>
      </div>
      <!--
      <el-button type="primary" @click="submit()" size="medium">提交</el-button>-->
    </div>
  </div>
</template>
<script>
import ApiCon from "../util/Api/index";
import * as imageConversion from 'image-conversion'
export default {
  name: "Reply",
  data() {
    return {
      content: "",
      title: this.$route.params.text,
      mediaid: this.$route.params.id,
      tag: "",
      baseUrl: '',
      baseUrls: 'https://www.sosunlive.com',
      yunlink: "",
      yuncode: "",
      type: '1',//this.$route.params.type,
      bt: '',
      weblink: '',
      info: '',
      fileListss: [],
      fileLists: [],
      fileList: [
        //{ name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100' }
      ],
    };
  },
  components: {

  },
  created() {
    let self = this
    if (self.$route.params.id != 0) {
      self.id = this.$route.params.id
      self.getProductInfo()
    }
  },
  methods: {
    backs() {
      history.go(-1);
    },
    getProductInfo() {
      let id = this.id;
      let self = this
      this.$api.mall.getDetail({ id: id }).then((res) => {
        //res.data.data.tag = res.data.data.tag.split(",")
        self.title = res.data.data.title
        self.info = res.data.data
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      });
    },
    handleSuccess(res, file, fileList) {
      if (res.code == 1) {
        this.fileList.push({
          name: res.data.url,
          fileId: res.data.url,
          url: res.data.url,
        });
        this.$emit("input", this.fileList);
      } else {
        fileList.filter((o) => o.uid != file.uid);
        this.$emit("input", this.fileList);
        this.$message({ message: res.msg, type: "error", duration: 1500 });
      }
    },
    handleSuccesss(res, file, fileList) {
      if (res.code == 1) {
        this.fileLists.push({
          name: res.data.url,
          fileId: res.data.url,
          url: res.data.url,
        });
        this.$emit("input", this.fileLists);
      } else {
        fileList.filter((o) => o.uid != file.uid);
        this.$emit("input", this.fileLists);
        this.$message({ message: res.msg, type: "error", duration: 1500 });
      }
    },
    handleSuccessss(res, file, fileList) {
      if (res.code == 1) {
        this.fileListss.push({
          name: res.data.url,
          fileId: res.data.url,
          url: res.data.url,
        });
        this.$emit("input", this.fileListss);
      } else {
        fileList.filter((o) => o.uid != file.uid);
        this.$emit("input", this.fileListss);
        this.$message({ message: res.msg, type: "error", duration: 1500 });
      }
    },
    beforeUpload(file) {
      let self=this
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      // const isLtSize = file.size / 1024 / 1024 < 2;
      if (!isImage) {
          this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
          return false;
      }
      //图片压缩插件
      return new Promise((resolve) => {
          // 压缩到100KB,这里的100就是要压缩的大小,可自定义
          imageConversion.compressAccurately(file, 100).then(res => {
              console.log(res);
              let files = new File([res], file.name, { type: file.type });
              resolve(files)
          });
      })
      /**
      let isRightSize = file.size / (1024 * 500) < 0.5;
      if (!isRightSize) {
        this.$message.error(`文件不要超过500KB`);
        return false;
      }
      if(self.fileList.length>9){
        self.$message.error(`最多上传9张图片`);
        return false
      }**/
    },
    beforeUploadss(file) {
      let self=this
      let isRightSize = file.size / (1024 * 500) < 0.5;
      if (!isRightSize) {
        this.$message.error(`文件不要超过500KB`);
        return false;
      }
    },
    beforeUploads(file) {
      let self=this
      let isRightSize = file.size / (1024 * 1024) < 35;
      if (!isRightSize) {
        this.$message.error(`文件不要超过35MB`);
        return false;
      }
      if(self.fileLists.length>1){
        self.$message.error(`最多上传1个视频`);
        return false
      }
    },
    submit() {
      let self = this;
      if (!window.localStorage.getItem("token")) {
        self.$message.error("请先登录或者注册");
        return;
      }
      if (!self.title) {
        self.$layer.msg("请输入标题");
        return;
      }
      if (self.type == 4) {
        if (!self.yunlink) {
          self.$layer.msg("请输入云盘链接");
          return;
        }
        /**
        if (!self.yuncode) {
          self.$layer.msg("请输入云盘分享码");
          return;
        }
        **/
      }
      if (self.type == 5) {
        if (!self.weblink) {
          self.$layer.msg("请输入网站链接");
          return;
        }
      }

      if (self.type == 1) {
        if (self.fileList.length<3) {
          self.$layer.msg("请上传至少三张图片");
          return;
        }
      }
      
      if (self.type == 2) {
        if (self.fileLists.length==0) {
          self.$layer.msg("请上传视频");
          return;
        }
      }
      /**
      let attachfile = ''
      if (self.fileLists.length > 0) {
          attachfile = self.fileLists[0].url
      }**/
      
      let attachfile=''
      if (self.fileLists.length > 0) {
        for (let a = self.fileLists.length - 1; a >= 0; a--) {
          attachfile = self.fileLists[a].url + "," + attachfile;
        }
      }
      if(attachfile){
        attachfile = attachfile.slice(0, attachfile.length - 1);
      }
      let images=''
      if (self.fileList.length > 0) {
        for (let a = self.fileList.length - 1; a >= 0; a--) {
          images = self.fileList[a].url + "," + images;
        }
      }
      if(images){
        images = images.slice(0, images.length - 1);
      }
      this.$api.mall
        .resourcesAdd({
          images: images,
          attachfile: attachfile,
          //paypic: self.fileListss[0],
          title: self.title,
          tag: self.title,
          yunlink: self.yunlink,
          yuncode: self.yuncode,
          weblink: self.weblink,
          media_id: self.mediaid,
          type: self.type,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            self.$message.success("提交成功");
            self.fileList = [];
            self.fileLists = [];
            self.fileListss = [];
            //self.title = "";
            self.weblink = "";
            self.yuncode = '';
            self.yunlink = '';
            self.bt = '';
          } else {
            self.$message.error("提交失败，请稍后再试");
          }
        });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    }
  },
};
</script>
<style lang="scss" scoped>
@media (max-width: 1920px) and (min-width: 1024px) {
  .replys {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;

    .centers-el {
      width: 630px;
      overflow: hidden;
      margin: 48px auto auto auto;

      .media-center {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px auto;
      }

      .movie-body {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        background: #fff;
        border-radius: 2px;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 16px 15px 10px;

        .filename-centers {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          box-sizing: border-box;
          padding: 10px;

          .filename-pic {
            width: 98px;
            overflow: hidden;
            float: left;
            margin: 0px auto;
          }

          .filename-content {
            width: calc(100% - 108px);
            overflow: hidden;
            margin: 0px auto;
            float: right;
            padding: 0px;
          }

          .font-text {
            width: 100%;
            line-height: 20px;
            font-size: 13px;
            overflow: hidden;
            /**
              width: 100%;
              line-height: 1.5;
              font-size: 12px;
              overflow: hidden;
              word-wrap: break-word; //自动换行
              word-break: break-all; //自动换行（兼容字母）
              overflow: hidden; //超出隐藏
              text-overflow: ellipsis; //溢出显示省略号
              display: -webkit-box;
              -webkit-line-clamp: 1; //显示3行
              -webkit-box-orient: vertical;
            */
          }
        }
      }

      .editor-center {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        background: #fff;
        overflow: hidden;
        margin: 0px auto;

        .inputs {
          width: 100%;
          overflow: hidden;
          margin: 10px auto;
        }
      }

      .centers-images {
        width: 100%;
        box-sizing: border-box;
        padding: 10px;
        overflow: hidden;
        margin: 0px auto;
      }
    }

    .page-el {
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      overflow: hidden;
      margin: 0px auto;
      bottom: 0px;
      background: #fff;
      left: 0px;
      box-shadow: 0 -3px 6px rgba(0, 0, 0, .06);
      z-index: 100;
      position: fixed;

      .page-bottom {
        width: 630px;
        overflow: hidden;
        margin: 0px auto;
        box-sizing: border-box;
        padding: 10px;

        .left {
          width: 50%;
          overflow: hidden;
          float: left;
          text-align: left;
        }

        .right {
          width: 50%;
          overflow: hidden;
          float: right;
          text-align: right;
        }
      }
    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      background: #ffe url('/imgs/fade.png') top center repeat-x;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;

      .mobiles-nav-list {
        display: none;
      }

      .nav-list {
        width: 630px;
        overflow: hidden;
        margin: 0px auto;

        .searchTexts {
          width: 50%;
          float: right;
          overflow: hidden;
          margin: 6px auto;
        }

        .nav-backs {
          text-decoration: none;
          padding: 1px;
          display: block;
          font-size: 14px;
          margin-left: 6px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 42px;
          text-align: center;
          float: left;
          cursor: pointer;
        }

        .nav-items {
          text-decoration: none;
          padding: 1px;
          display: block;
          font-size: 14px;
          margin-left: 6px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 42px;
          text-align: center;
          float: left;
        }

        .nav-backs:hover {
          color: red !important;
          text-decoration: underline;
        }
      }
    }
  }
}

@media (max-width: 1024px) and (min-width: 320px) {
  .replys {
    width: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 0px;

    .page-el {
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      background: #fff;
      overflow: hidden;
      margin: 0px auto;
      bottom: 0px;
      left: 0px;
      box-shadow: 0 -3px 6px rgba(0, 0, 0, .06);
      z-index: 100;
      position: fixed;

      .page-bottom {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        box-sizing: border-box;
        padding: 10px;

        .left {
          width: 50%;
          overflow: hidden;
          float: left;
          text-align: left;
        }

        .right {
          width: 50%;
          overflow: hidden;
          float: right;
          text-align: right;
        }
      }
    }

    .centers-el {
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      margin: 60px auto auto auto;

      .media-center {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px auto;
      }

      .movie-body {
        width: 100%;
        overflow: hidden;
        margin: 0px auto;
        background: #fff;
        border-radius: 2px;
        -webkit-box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 16px 15px 10px;

        .filename-centers {
          width: 100%;
          overflow: hidden;
          margin: 0px auto;
          box-sizing: border-box;
          padding: 10px;

          .filename-pic {
            width: 98px;
            overflow: hidden;
            float: left;
            margin: 0px auto;
          }

          .filename-content {
            width: calc(100% - 108px);
            overflow: hidden;
            margin: 0px auto;
            float: right;
            padding: 0px;
          }

          .font-text {
            width: 100%;
            line-height: 18px;
            font-size: 13px;
            overflow: hidden;
            /**
              width: 100%;
              line-height: 1.5;
              font-size: 12px;
              overflow: hidden;
              word-wrap: break-word; //自动换行
              word-break: break-all; //自动换行（兼容字母）
              overflow: hidden; //超出隐藏
              text-overflow: ellipsis; //溢出显示省略号
              display: -webkit-box;
              -webkit-line-clamp: 1; //显示3行
              -webkit-box-orient: vertical;
            */
          }
        }
      }

      .editor-center {
        width: 98%;
        box-sizing: border-box;
        padding: 10px;
        background: #fff;
        overflow: hidden;
        margin: 0px auto;

        .inputs {
          width: 100%;
          overflow: hidden;
          margin: 10px auto;
        }
      }

      .centers-images {
        width: 98%;
        box-sizing: border-box;
        padding: 10px;
        overflow: hidden;
        margin: 0px auto;
      }
    }

    .nav-topbar {
      width: 100%;
      overflow: hidden;
      position: fixed;
      top: 0px;
      left: 0px;
      z-index: 10;
      background: url('/imgs/fade.png') top center repeat-x;

      .mobiles-nav-list {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        padding: 5px;
        margin: 0px auto;

        .nav-backs {
          text-decoration: none;
          padding: 1px;
          display: block;
          font-size: 14px;
          margin-left: 10px;
          font-weight: 400;
          overflow: hidden;
          color: maroon;
          line-height: 30px;
          text-align: center;
          float: left;
        }
      }

      .nav-list {
        display: none;
      }
    }
  }
}
</style>