<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'nav-home',
  components: { 

  }
};
</script>

<style lang='scss'>

</style>